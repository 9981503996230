import { Link } from 'react-router-dom';
import './PrimaryButton.css';

type PrimaryButtonType = {
	text: string,
	href: string
}

export default function PrimaryLinkButton(props: PrimaryButtonType) {
	return (
		<Link to={props.href}>
			<button style={{margin: '25px'}} className='nes-btn primary-button'>{props.text}</button>
		</Link>
	);
}


export function PrimaryRedirectButton(props: PrimaryButtonType) {
	return (
		<a href="https://google.com">
			<button style={{margin: '25px'}} className='nes-btn primary-button'>{props.text}</button>
		</a>
	);
}