import '../App.css';
import {Box} from "@material-ui/core";
import PrimaryButton from '../common/PrimaryButton';

import riskNft from "../assets/riskNft.gif";
import RiskNftContainer from '../common/RiskNftContainer';

function Main() {
	return (
		<Box style={{display: "flex", flexDirection: "column", alignItems: 'center', marginTop: "20px"}}>
			<RiskNftContainer>
				<img src={riskNft} style={{width: "20vw"}}/>
			</RiskNftContainer>
			<div>
				{/* <PrimaryButton text='vaults' href='coming-soon'></PrimaryButton>
				<PrimaryButton text='shop' href='shop'></PrimaryButton> */}
				<PrimaryButton text='xNFT' href='/xnft'></PrimaryButton>
				<PrimaryButton text='NFT' href='/tensor'></PrimaryButton>
				{/* <PrimaryButton text='LST' href='/lst'></PrimaryButton> */}
			</div>
		</Box>
)
}

export default Main;
