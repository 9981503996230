import React from 'react';
import './index.css';
import Main from './pages/Main';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ComingSoon from './pages/ComingSoon';
import Banner from './components/Banner';
// import Token from './pages/Token';

import ReactDOM from 'react-dom';

export default function Tensor() {    
    window.location.replace('https://www.tensor.trade/trade/risk_lol')
    return (<div style={{textAlign: "center", marginTop: "100px"}}>
		<p style={{textAlign: "center"}}>
			Redirecting to https://tensor.trade....
		</p>
	</div>)
}

ReactDOM.render(
	<React.StrictMode>
	<div className='App'>
		<BrowserRouter>
			<Banner/>
			<Routes>
				<Route path='/xnft' element={<ComingSoon/>}/>
				<Route path='/' element={<Main/>}/>
				<Route path='/tensor' element={<Tensor/>}/>
				<Route path='/lst' element={<ComingSoon/>}/>
			</Routes>
		</BrowserRouter>
	</div>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
