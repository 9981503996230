// import { useLocation, useNavigate } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom';
import {ReactComponent as Logo} from '../assets/risklolLogo.svg';
// import PrimaryButton from '../common/PrimaryButton';
import PrimaryButton from '../common/PrimaryButton';

export default function  Banner() {
	const location = useLocation();
	return (
			<div style={{textAlign: 'center'}}>
				<Link to='/'>
					<Logo/>
				</Link>	
				{/* 
					on the main page, we render navbar differently
					this excludes the navbar in the banner on / and
					renders on every other page
				 */}
				{location.pathname === "/" ? <></>: 
					<div>
						<PrimaryButton text='xnft' href='/xnft'></PrimaryButton>
						<PrimaryButton text='nft' href='/tensor'></PrimaryButton>
						{/* <PrimaryButton text='lst' href='/lst'></PrimaryButton> */}
					</div>
				}
			</div>
	)
}
